import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Nav from './nav';

import style from './header.module.scss';

const Header = ({ siteTitle }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      const scrollPos = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      // eslint-disable-next-line no-restricted-globals
      window.scrollTo(0, parseInt(scrollPos || '0') * -1);
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={style.header}>
      <div className={style.header_container}>
        <div className={style.header_container__headings}>
          <h1 style={{ margin: 0 }}>
            <Link to="/">{siteTitle}</Link>
          </h1>
          <h2>Ultimate World Triathlon</h2>
        </div>
        <div className={style.header_container__menu}>
          <svg
            className={style.header_container__menu__open}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            onClick={toggleMenu}
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
          {isMenuOpen && <Nav toggleMenu={toggleMenu} />}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
