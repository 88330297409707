import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import media from '../data/media.json';
import style from './pages.module.scss';

const Media = ({ data }) => {
  const getSvg = type => {
    switch (type) {
      case 'article':
        return (
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="microphone"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M160 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S64 42.98 64 96v160c0 53.02 42.98 96 96 96zM96 96c0-35.29 28.71-64 64-64s64 28.71 64 64v160c0 35.29-28.71 64-64 64s-64-28.71-64-64V96zm216 96h-16c-4.42 0-8 3.58-8 8v56c0 73.46-62.2 132.68-136.73 127.71C83.3 379.18 32 319.61 32 251.49V200c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v50.34c0 83.39 61.65 156.12 144 164.43V480H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h176c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-72v-65.01C256.71 406.9 320 338.8 320 256v-56c0-4.42-3.58-8-8-8z"
            ></path>
          </svg>
        );
      case 'video':
        return (
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="video"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M543.9 96c-6.2 0-12.5 1.8-18.2 5.7L416 171.6v-59.8c0-26.4-23.2-47.8-51.8-47.8H51.8C23.2 64 0 85.4 0 111.8v288.4C0 426.6 23.2 448 51.8 448h312.4c28.6 0 51.8-21.4 51.8-47.8v-59.8l109.6 69.9c5.7 4 12.1 5.7 18.2 5.7 16.6 0 32.1-13 32.1-31.5v-257c.1-18.5-15.4-31.5-32-31.5zM384 400.2c0 8.6-9.1 15.8-19.8 15.8H51.8c-10.7 0-19.8-7.2-19.8-15.8V111.8c0-8.6 9.1-15.8 19.8-15.8h312.4c10.7 0 19.8 7.2 19.8 15.8v288.4zm160-15.7l-1.2-1.3L416 302.4v-92.9L544 128v256.5z"
            ></path>
          </svg>
        );
      case 'audio':
        return (
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="microphone"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path
              fill="currentColor"
              d="M160 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S64 42.98 64 96v160c0 53.02 42.98 96 96 96zM96 96c0-35.29 28.71-64 64-64s64 28.71 64 64v160c0 35.29-28.71 64-64 64s-64-28.71-64-64V96zm216 96h-16c-4.42 0-8 3.58-8 8v56c0 73.46-62.2 132.68-136.73 127.71C83.3 379.18 32 319.61 32 251.49V200c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v50.34c0 83.39 61.65 156.12 144 164.43V480H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h176c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-72v-65.01C256.71 406.9 320 338.8 320 256v-56c0-4.42-3.58-8-8-8z"
            ></path>
          </svg>
        );
      case 'external-link':
      default:
        return (
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="external-link"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M195.515 374.828c-4.686-4.686-4.686-12.284 0-16.971l323.15-323.15-.707-.707-89.958.342c-6.627 0-12-5.373-12-12v-9.999c0-6.628 5.372-12 12-12L564 0c6.627 0 12 5.372 12 12l-.343 136c0 6.627-5.373 12-12 12h-9.999c-6.627 0-12-5.373-12-12L542 58.042l-.707-.707-323.15 323.15c-4.686 4.686-12.284 4.686-16.971 0l-5.657-5.657zm232-155.633l-8 8A12 12 0 0 0 416 235.68V464c0 8.837-7.164 16-16 16H48c-8.836 0-16-7.163-16-16V112c0-8.837 7.164-16 16-16h339.976c3.183 0 6.235-1.264 8.485-3.515l8-8c7.56-7.56 2.206-20.485-8.485-20.485H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V227.681c0-10.691-12.926-16.045-20.485-8.486z"
            ></path>
          </svg>
        );
    }
  };
  const ml = media.data
    .filter(item => item.title)
    .map(item => {
      return (
        <li key={item.link} className={style[item.type]}>
          <div>
            <h2>
              <a href={item.link} target="_blank" rel="noopener noreferrer">
                {item.title}
                {getSvg('external-link')}
              </a>
            </h2>
            <h3>{item.source}</h3>
            <p>{item.excerpt}</p>
          </div>
        </li>
      );
    });
  return (
    <Layout>
      <SEO
        title="Media"
        description="Rob Lea has appeared in various media outlets including: Men's Journal, The New York Times, National Geographic, Outside Magazine, The Park Record, The Salt Lake Tribune, and many more."
        image="/images/meta-media.jpg"
        pathname="/media"
      />
      <div className={[style.hero, style.hero__media].join(' ')}>&nbsp;</div>
      <div className={[style.page, style.page__homepage].join(' ')}>
        <div className={style.feature_odd}>
          <div className={style.container}>
            <h1>Media</h1>
            <ul className={style.mediaList}>{ml}</ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Media;
