/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  lang,
  meta,
  title,
  useTitleTemplate,
  image,
  externalImage,
  pathname,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl: url
            defaultTitle: title
            defaultDescription: description
            defaultTwitterUsername: author
            defaultImage: image
          }
        }
      }
    `
  );

  const seo = {
    title: useTitleTemplate
      ? `${title || site.siteMetadata.defaultTitle} | ${
          site.siteMetadata.defaultTitle
        }`
      : title || site.siteMetadata.defaultTitle,
    description: description || site.siteMetadata.defaultDescription,
    image: externalImage
      ? externalImage
      : `${site.siteMetadata.siteUrl}${image ||
          site.siteMetadata.defaultImage}`,
    url: `${site.siteMetadata.siteUrl}${pathname || '/'}`,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      meta={[
        {
          name: `title`,
          content: seo.title,
        },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: seo.url,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  useTitleTemplate: true,
  image: null,
  externalImage: null,
  pathname: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  useTitleTemplate: PropTypes.bool,
  image: PropTypes.string,
  externalImage: PropTypes.string,
  pathname: PropTypes.string,
};

export default SEO;
